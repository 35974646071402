import React from "react";
import {Button, SectionHeading, Table, TableButton} from "peggirkit";
import {HelpCenterCategoryDetails} from "../../../data/Entities";
import router from "../../../routing/Router";
import {PlusIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";


const Category = ({id, name, slug, posts}: HelpCenterCategoryDetails) => {
    const navigate = useNavigate();
    return (
        <div>
            <div className={"w-full flex flex-row justify-between items-start"}>
                <SectionHeading
                    title={name}
                    description={`/${slug}`}
                />
                <Button
                    text={"New"}
                    icon={PlusIcon}
                    onClick={() => navigate(router.newHelpCenterPost.absolutePath(id))}
                />
            </div>

            <Table
                columns={[
                    {
                        name: "ID",
                        sortable: false,
                        secondary: true,
                    },
                    {
                        name: "Order no.",
                        sortable: false,
                        secondary: true,
                    },
                    {
                        name: "Title",
                        sortable: false,
                    },
                    {
                        name: "Last edited",
                        sortable: false,
                        alignRight: true,
                        secondary: true,
                    },
                    {
                        name: "Options",
                        sortable: false,
                        hideLabel: true,
                        alignRight: true,
                        secondary: true,
                    },
                ]}
                data={posts.map(p => ({
                    "ID": p.id,
                    "Order no.": p.orderNumber,
                    "Title": p.title,
                    "Last edited": new Date(p.lastEditedDate || "").toLocaleDateString(),
                    "Options": <TableButton
                        text={"Edit"}
                        href={router.editHelpCenterPost.absolutePath(p.id)}
                    />
                }))}
            />
        </div>
    );
};

export default Category;