import React from "react";
import {AuthFormFooter} from "peggirkit";


const AuthFormFooterSignIn = () => {
    return (
        <AuthFormFooter
            message={"Need to go to client app?"}
            link={"https://app.serpotrack.com/"}
            linkText={"App"}
        />
    );
};

export default AuthFormFooterSignIn;