import React from "react";
import {DescriptionList, SectionHeading} from "peggirkit";
import {UserDetails} from "../../../../../data/Entities";

type Props = {
    user: UserDetails
};

const GeneralSection = ({user}: Props) => {
    return (
        <>
            <SectionHeading
                title={"General"}
                description={"General account information."}
            />

            <DescriptionList
                items={[
                    {
                        key: "ID",
                        value: user.id + ""
                    },
                    {
                        key: "E-mail address",
                        value: user.username
                    },
                    {
                        key: "Mollie customer ID",
                        value: user.mollieCustomerId
                    },
                    {
                        key: "Verified",
                        value: user.verified ? "Yes" : "No"
                    },
                    {
                        key: "Joined on",
                        value: new Date(user.creationDate).toLocaleString()
                    },
                    {
                        key: "Used trial",
                        value: user.usedTrial ? "Yes" : "No"
                    }
                ]}
            />
        </>
    );
};

export default GeneralSection;