import {Button, DayPicker, FormLayoutCard, FormLayoutFooterOptions, InputField, TwoColumnFormLayout} from "peggirkit";
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import router from "../../../../routing/Router";
import {ArrowUpTrayIcon, CalendarIcon, TrashIcon} from "@heroicons/react/24/outline";
import {getBlogPost, saveBlogPost} from "../../../../data/SerpotrackAdmin";
import {BlogPost, ImageType} from "../../../../data/Entities";
import {UseFormSetValue} from "react-hook-form/dist/types/form";
import ImageUploader from "./image/ImageUploader";
import dayjs from "dayjs";
import DeleteBlogPost from "./DeleteBlogPost";
import ContentImageUploader from "../../ContentImageUploader";

const setBlogPostValues = (setValue: UseFormSetValue<BlogPost>, blogPost: BlogPost) => {
    setValue("id", blogPost.id);
    setValue("title", blogPost.title);
    setValue("banner", blogPost.banner);
    setValue("slug", blogPost.slug);
    setValue("description", blogPost.description);
    setValue("content", blogPost.content);
    setValue("lastEditedDate", blogPost.lastEditedDate);
    setValue("publishDate", blogPost.publishDate);
}

const EditBlogPostForm = () => {
    const navigate = useNavigate();
    const {blogPostId} = useParams();
    const {register, setValue, getValues, watch, handleSubmit, formState: {errors}} = useForm<BlogPost>({
        defaultValues: {
            id: undefined,
            title: "",
            banner: "",
            slug: "",
            description: "",
            content: "",
            publishDate: ""
        }
    });
    const [loading, setLoading] = useState(false);
    const [showSelectPublishDay, setShowSelectPublishDay] = useState(false);
    const [showDeleteBlogPostDialog, setShowDeleteBlogPostDialog] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);
    const bannerUploaderRef = useRef<HTMLInputElement | null>(null);

    // Load existing data
    useEffect(() => {
        if (!blogPostId) {
            // New blog post
            return;
        }

        getBlogPost(parseInt(blogPostId)).then((existingPost: BlogPost) => {
            setBlogPostValues(setValue, existingPost);
        });
    }, []);

    // Save post
    useEffect(() => {
        if (!loading) {
            return;
        }

        const values = getValues();
        saveBlogPost({
            id: values.id,
            title: values.title,
            banner: values.banner,
            slug: values.slug,
            description: values.description,
            content: values.content,
            publishDate: values.publishDate
        }).then((saved: BlogPost) => {
            setLoading(false);
            setBlogPostValues(setValue, saved);
        }).catch(() => {
            setLoading(false);
            setFormError("Error saving blog post.");
        });
    }, [loading]);

    return (
        <TwoColumnFormLayout
            onSubmit={handleSubmit(() => setLoading(true))}
            error={formError}
        >
            <FormLayoutCard
                title={"General"}
                description={"General blog details."}
            >
                <div className="grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-4">
                        <InputField
                            type={"text"}
                            id={"id"}
                            displayName={"ID"}
                            reactHookForm={{...register("id")}}
                            disabled={true}
                        />
                    </div>
                    <div className="col-span-4 sm:col-span-4">
                        <InputField
                            type={"text"}
                            id={"title"}
                            displayName={"Title"}
                            tip={`${watch("title")?.length || 0}/512`}
                            reactHookForm={{
                                ...register("title", {
                                    required: true,
                                    minLength: {
                                        value: 1,
                                        message: "Too short"
                                    },
                                    maxLength: {
                                        value: 512,
                                        message: "Too long"
                                    }
                                })
                            }}
                            error={errors.title && errors.title.message}
                            disabled={loading}
                        />
                    </div>
                    <div className="col-span-4 sm:col-span-4">
                        <InputField
                            type={"text"}
                            id={"slug"}
                            displayName={"Slug"}
                            tip={`${watch("slug")?.length || 0}/512`}
                            reactHookForm={{
                                ...register("slug", {
                                    required: true,
                                    maxLength: {
                                        value: 512,
                                        message: "Too long"
                                    }
                                })
                            }}
                            error={errors.slug && errors.slug.message}
                            disabled={loading}
                        />
                    </div>
                    <div className="col-span-4 sm:col-span-4">
                        <InputField
                            type={"textarea"}
                            id={"description"}
                            textareaHeight={"h-24"}
                            displayName={"Meta description"}
                            tip={`${watch("description")?.length || 0}/512`}
                            reactHookForm={{
                                ...register("description", {
                                    required: true,
                                    maxLength: {
                                        value: 512,
                                        message: "Too long"
                                    }
                                })
                            }}
                            error={errors.description && errors.description.message}
                            disabled={loading}
                        />
                    </div>
                    <div className="col-span-4 sm:col-span-4">
                        <InputField
                            type={"text"}
                            id={"banner"}
                            displayName={"Banner"}
                            tip={"2504x800 px"}
                            reactHookForm={{
                                ...register("banner", {
                                    required: true,
                                    maxLength: {
                                        value: 256,
                                        message: "Too long"
                                    }
                                })
                            }}
                            error={errors.banner && errors.banner.message}
                            disabled={loading}
                            trailingButton={{
                                type: "gray",
                                text: "Upload",
                                icon: ArrowUpTrayIcon,
                                disabled: loading,
                                onClick: () => bannerUploaderRef.current && bannerUploaderRef.current.click()
                            }}
                        />
                        <ImageUploader
                            imageType={ImageType.banner}
                            afterUpload={(img) => setValue("banner", img)}
                            bannerUploaderRef={bannerUploaderRef}
                        />
                    </div>
                </div>
            </FormLayoutCard>

            <FormLayoutCard
                title={"Content"}
                description={"Blog post content."}
            >
                <div className="grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-4">
                        <InputField
                            type={"textarea"}
                            id={"content"}
                            textareaHeight={"h-96"}
                            displayName={"Content"}
                            reactHookForm={{
                                ...register("content", {
                                    required: true,
                                })
                            }}
                            error={errors.content && errors.content.message}
                            disabled={loading}
                        />
                    </div>
                    <div className="col-span-4 sm:col-span-4">
                        <ContentImageUploader
                            disabled={loading}
                            afterUpload={(htmlCode: string) => {
                                setValue("content", watch("content") + "\n" + htmlCode);
                            }}
                        />
                    </div>
                </div>
            </FormLayoutCard>

            <FormLayoutCard
                title={"Publish"}
                description={"Publish details for the post."}
            >
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 xl:col-span-3">
                        <InputField
                            type={"text"}
                            id={"lastEditedDate"}
                            displayName={"Last edited on"}
                            disabled={true}
                            reactHookForm={{...register("lastEditedDate")}}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 xl:col-span-3">
                        <InputField
                            type={"text"}
                            id={"publishDate"}
                            displayName={"Publish on"}
                            tip={"Select a future day to schedule or keep a post private."}
                            reactHookForm={{
                                ...register("publishDate", {
                                    required: true,
                                    maxLength: {
                                        value: 256,
                                        message: "Too long"
                                    }
                                })
                            }}
                            error={errors.publishDate && errors.publishDate.message}
                            disabled={loading}
                            trailingButton={{
                                type: "gray",
                                text: "Pick a day",
                                icon: CalendarIcon,
                                disabled: loading,
                                onClick: (e) => {
                                    e.preventDefault();
                                    setShowSelectPublishDay(true);
                                }
                            }}
                        />
                        <DayPicker
                            defaultSelected={watch("publishDate") ? new Date(watch("publishDate")) : new Date()}
                            title={"Publish day"}
                            cancelText={"Cancel"}
                            continueText={"Ok"}
                            show={showSelectPublishDay}
                            setShow={setShowSelectPublishDay}
                            onSelect={(selected) => {
                                if (selected) {
                                    setValue("publishDate", dayjs(selected).format("YYYY-MM-DD[T]HH:mm:ss[+0000]"));
                                }
                            }}
                        />
                    </div>
                </div>
            </FormLayoutCard>

            {
                blogPostId !== undefined
                    ? <FormLayoutCard
                        title={"Delete"}
                        description={"Permanently delete this post."}
                    >
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <Button
                                    type={"danger"}
                                    icon={TrashIcon}
                                    text={"Delete"}
                                    disabled={loading}
                                    onClick={() => setShowDeleteBlogPostDialog(true)}
                                />
                            </div>
                        </div>
                        <DeleteBlogPost
                            blogPostId={parseInt(blogPostId)}
                            show={showDeleteBlogPostDialog}
                            setShow={setShowDeleteBlogPostDialog}
                        />
                    </FormLayoutCard>
                    : <></>
            }

            <FormLayoutFooterOptions
                cancelText={"Cancel"}
                onCancel={() => navigate(router.blog.absolutePath())}
                submitText={"Save"}
                submitLoading={loading}
                disabled={loading}
            />
        </TwoColumnFormLayout>
    );
};

export default EditBlogPostForm;