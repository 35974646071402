import React from "react";
import {breadcrumbItemDashboard, breadcrumbItemUsers} from "../../../routing/Router";
import {BreadcrumbsContent, PageHeading} from "peggirkit";
import SerpotrackFrame from "./layout/SerpotrackFrame";
import UsersDataTable from "../../widgets/users/UsersDataTable";

const Users = () => {
    const breadcrumbs: BreadcrumbsContent = {
        backText: "Back",
        items: [breadcrumbItemDashboard, breadcrumbItemUsers]
    };

    return (
        <SerpotrackFrame title={breadcrumbItemUsers.name}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={breadcrumbItemUsers.name}
            />

            <UsersDataTable/>
        </SerpotrackFrame>
    );
};

export default Users;