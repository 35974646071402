import React from "react";
import {SectionHeading, Table} from "peggirkit";
import {UserDetails} from "../../../../../data/Entities";

type Props = {
    user: UserDetails
};

const ProjectsTab = ({user}: Props) => {
    return (
        <div>
            <SectionHeading
                title={"Projects"}
                description={"Projects created by this user."}
            />

            <Table
                columns={[
                    {
                        name: "ID",
                        sortable: false,
                        secondary: true
                    },
                    {
                        name: "Name",
                        sortable: false
                    },
                    {
                        name: "URL",
                        sortable: false,
                        secondary: true
                    },
                    {
                        name: "GL",
                        sortable: false,
                        secondary: true
                    },
                    {
                        name: "HL",
                        sortable: false,
                        secondary: true
                    },
                    {
                        name: "Device",
                        sortable: false,
                        secondary: true
                    },
                    {
                        name: "Created",
                        sortable: false,
                        secondary: true
                    }
                ]}
                data={user.projects.map(p => ({
                    ["ID"]: p.id,
                    ["Name"]: p.name,
                    ["URL"]: p.url,
                    ["GL"]: p.geographicalLocation,
                    ["HL"]: p.homeLocation,
                    ["Device"]: p.device,
                    ["Created"]: new Date(p.creationDate).toLocaleString()
                }))}
            />
        </div>
    );
};

export default ProjectsTab;