import React, {useRef} from "react";
import {Button} from "peggirkit";
import {PhotoIcon} from "@heroicons/react/24/outline";
import ImageUploader from "./blog/edit/image/ImageUploader";
import {ImageType} from "../../data/Entities";

type Props = {
    disabled: boolean,
    afterUpload: (htmlCode: string) => void
};

const ContentImageUploader = ({disabled, afterUpload}: Props) => {
    const contentImageUploaderRef = useRef<HTMLInputElement | null>(null);

    return (
        <>
            <Button
                type={"white"}
                icon={PhotoIcon}
                text={"Insert image"}
                disabled={disabled}
                onClick={e => {
                    e.preventDefault();
                    contentImageUploaderRef.current && contentImageUploaderRef.current.click()
                }}
            />
            <ImageUploader
                imageType={ImageType.content}
                afterUpload={(img, width, height) => {
                    const fileNameParts = img.split(".");
                    const fileNameWithoutExtension = fileNameParts[0];
                    const fileNameExtension = fileNameParts[fileNameParts.length - 1];
                    afterUpload(
                        "<picture>\n" +
                        "    <source srcset=\"https://assets.serpotrack.com/" + fileNameWithoutExtension + ".min.webp\" type=\"image/webp\"/>\n" +
                        "    <source srcset=\"https://assets.serpotrack.com/" + fileNameWithoutExtension + ".min." + fileNameExtension + "\"/>\n" +
                        "    <img itemprop=\"image\" src=\"https://assets.serpotrack.com/" + fileNameWithoutExtension + ".min." + fileNameExtension + "\" alt=\"\" width=\"" + width + "\" height=\"" + height + "\"/>\n" +
                        "</picture>"
                    );
                }}
                bannerUploaderRef={contentImageUploaderRef}
            />
        </>
    );
};

export default ContentImageUploader;