import React, {useState} from "react";
import {Button, DescriptionList, SlideOver, TableButton} from "peggirkit";
import {PlanDetails} from "../../../../../data/Entities";

type Props = {
    plan: PlanDetails
};

const PlanDetailsSection = ({plan}: Props) => {
    const [showPlan, setShowPlan] = useState(false);
    return (
        <>
            <TableButton
                text={plan.id + ""}
                href={"#"}
                onClick={e => {
                    e.preventDefault();
                    setShowPlan(true);
                }}
            />
            <SlideOver
                open={showPlan}
                setOpen={setShowPlan}
                title={`Details: ${plan.name}`}
            >
                <DescriptionList
                    skipFirstBorder={true}
                    whitespaceNormal={true}
                    items={[
                        {key: "ID", value: "-1"},
                        {key: "Name", value: plan.name},
                        {key: "Projects", value: plan.projectLimit},
                        {key: "Keywords", value: plan.keywordLimit},
                        {key: "Update interval", value: plan.keywordUpdateHourlyIntervalLimit},
                        {key: "Max. position", value: plan.keywordMaxPositionLimit},
                        {key: "History limit", value: plan.historyLimit},
                        {key: "Can export data", value: plan.canExportData},
                        {key: "Price/mo (ex. tax)", value: `${plan.price_ex_tax_monthly} (€ct.)`},
                        {key: "Price/mo (in. tax)", value: `${plan.price_in_tax_monthly} (€ct.)`},
                        {key: "Price/yr (ex. tax)", value: `${plan.price_ex_tax_yearly} (€ct.)`},
                        {key: "Price/yr (in. tax)", value: `${plan.price_in_tax_yearly} (€ct.)`},
                        {key: "Tax", value: `${plan.tax_percentage}%`},
                        {key: "Invoice description mo.", value: plan.invoice_description_monthly},
                        {key: "Invoice description yr.", value: plan.invoice_description_yearly}
                    ]}
                />
            </SlideOver>
        </>
    );
};

export default PlanDetailsSection;