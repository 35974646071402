import React from "react";
import {BreadcrumbsContent, PageHeading} from "peggirkit";
import {breadcrumbItemBlog, breadcrumbItemDashboard, breadcrumbItemEditBlogPost} from "../../../../routing/Router";
import SerpotrackFrame from "../layout/SerpotrackFrame";
import EditBlogPostForm from "../../../widgets/blog/edit/EditBlogPostForm";
import {useParams} from "react-router-dom";

const EditBlogPost = () => {
    const {blogPostId} = useParams();
    const breadcrumbItem = breadcrumbItemEditBlogPost(blogPostId && parseInt(blogPostId) || -1);
    const breadcrumbs: BreadcrumbsContent = {
        backText: "Back",
        items: [breadcrumbItemDashboard, breadcrumbItemBlog, breadcrumbItem]
    };

    return (
        <SerpotrackFrame title={breadcrumbItem.name}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={breadcrumbItem.name}
            />

            <EditBlogPostForm/>
        </SerpotrackFrame>
    );
};

export default EditBlogPost;