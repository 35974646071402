import React, {useMemo} from "react";
import {BreadcrumbsContent} from "peggirkit";
import {
    breadcrumbItemDashboard,
    breadcrumbItemEditHelpCenterPost,
    breadcrumbItemHelpCenter
} from "../../../../routing/Router";
import SerpotrackFrame from "../layout/SerpotrackFrame";
import {useParams, useSearchParams} from "react-router-dom";
import HelpCenterPostEditContainer from "../../../widgets/helpCenter/edit/HelpCenterPostEditContainer";

const EditHelpCenterPost = () => {
    const {helpCenterPostId} = useParams();
    const [searchParams] = useSearchParams();
    const categoryParam = useMemo(() => searchParams.get("category"), []);
    const breadcrumbItem = breadcrumbItemEditHelpCenterPost(helpCenterPostId && parseInt(helpCenterPostId) || -1);
    const breadcrumbs: BreadcrumbsContent = {
        backText: "Back",
        items: [breadcrumbItemDashboard, breadcrumbItemHelpCenter, breadcrumbItem]
    };

    return (
        <SerpotrackFrame title={breadcrumbItem.name}>
            <HelpCenterPostEditContainer
                breadcrumbs={breadcrumbs}
                currentBreadcrumb={breadcrumbItem}
                helpCenterPostId={helpCenterPostId}
                helpCenterCategoryId={categoryParam === null ? null : parseInt(categoryParam)}
            />
        </SerpotrackFrame>
    );
};

export default EditHelpCenterPost;