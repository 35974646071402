import React, {ChangeEvent} from "react";
import {uploadImage} from "../../../../../data/SerpotrackAdmin";
import {ImageType} from "../../../../../data/Entities";


type Props = {
    imageType: ImageType,
    afterUpload: (filename: string, width: number, height: number) => void,
    bannerUploaderRef: any
};

const ImageUploader = ({imageType, afterUpload, bannerUploaderRef}: Props) => {

    const handleUploadContentImage = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        uploadImage(formData, imageType).then(response => {
            if (e.target.files === null) {
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = (ev) => {
                if (ev.target === null) {
                    return;
                }

                const img = new Image();
                img.src = ev.target.result as string;
                img.onload = () => {
                    const {width, height} = img;
                    afterUpload(response.message, Math.round(width / 2), Math.round(height / 2));
                };
            };

        });
    };

    return (
        <input
            hidden
            accept="image/*"
            type="file"
            onChange={handleUploadContentImage}
            ref={bannerUploaderRef}
        />
    );
};

export default ImageUploader;