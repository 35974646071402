import React from "react";
import router, {breadcrumbItemBlog, breadcrumbItemDashboard} from "../../../../routing/Router";
import {BreadcrumbsContent, PageHeading} from "peggirkit";
import SerpotrackFrame from "../layout/SerpotrackFrame";
import BlogDataTable from "../../../widgets/blog/BlogDataTable";
import {PlusIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";

const Blog = () => {
    const navigate = useNavigate();
    const breadcrumbs: BreadcrumbsContent = {
        backText: "Back",
        items: [breadcrumbItemDashboard, breadcrumbItemBlog]
    };

    return (
        <SerpotrackFrame title={breadcrumbItemBlog.name}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={breadcrumbItemBlog.name}
                actions={{
                    moreButtonText: "More",
                    actions: [
                        {
                            text: "New post",
                            actionType: "button",
                            icon: PlusIcon,
                            onClick: () => navigate(router.newBlogPost.absolutePath()),
                            primary: true
                        }
                    ]
                }}
            />

            <BlogDataTable/>
        </SerpotrackFrame>
    );
};

export default Blog;