import React from "react";
import {SectionHeading, Table} from "peggirkit";
import {UserDetails} from "../../../../../data/Entities";

type Props = {
    user: UserDetails
};

const RolesSection = ({user}: Props) => {
    return (
        <>
            <SectionHeading
                className={"mt-10"}
                title={"Roles"}
                description={"Users authorization roles."}
            />
            <Table
                columns={[
                    {
                        name: "ID",
                        sortable: false,
                        secondary: true
                    },
                    {
                        name: "Name",
                        sortable: false
                    }
                ]}
                data={user.roles.map(r => ({["ID"]: r.id, ["Name"]: r.name}))}
            />
        </>
    );
};

export default RolesSection;