import React, {useEffect, useState} from 'react';
import {AlertModal, AlertText, NotificationService} from "peggirkit";
import {useNavigate} from "react-router-dom";
import router from "../../../../routing/Router";
import {deleteBlogPost} from "../../../../data/SerpotrackAdmin";

type Props = {
    blogPostId: number,
    show: boolean,
    setShow: (show: boolean) => void
};

const DeleteBlogPost = ({blogPostId, show, setShow}: Props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        deleteBlogPost(blogPostId).then(() => {
            navigate(router.blog.absolutePath());
        }).catch(() => {
            NotificationService.notify({type: "danger", text: "Error deleting blog post."});
            setLoading(false);
        });
    }, [loading]);

    return (
        <AlertModal
            type={"danger"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            onContinue={() => setLoading(true)}
            title={"Confirm delete"}
            cancelText={"Cancel"}
            continueText={"Delete"}
        >
            <AlertText>
                Are you sure you want to delete this blog post? This operation cannot be undone.
            </AlertText>
        </AlertModal>
    );
};

export default DeleteBlogPost;