import React, {useEffect, useState} from "react";
import {breadcrumbItemDashboard} from "../../../routing/Router";
import {BreadcrumbsContent, capitalizeFirstLetter, PageHeading, TextSkeleton, useAuth} from "peggirkit";
import SerpotrackFrame from "./layout/SerpotrackFrame";
import {User} from "serpotrack-connector";

const Projects = () => {
    const auth = useAuth();
    const [user, setUser] = useState<User | null>(null);
    const breadcrumbs: BreadcrumbsContent = {
        backText: "Back",
        items: [breadcrumbItemDashboard]
    };

    useEffect(() => {
        auth?.currentUser().then((user: User) => setUser(user));
    }, []);

    return (
        <SerpotrackFrame title={breadcrumbItemDashboard.name}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={breadcrumbItemDashboard.name}
            />

            <p className={"mt-8"}>
                Signed in as: {user === null ? <TextSkeleton width={"max-w-[100px]"}/> : capitalizeFirstLetter(user.username)}
            </p>
        </SerpotrackFrame>
    );
};

export default Projects;