import React from "react";
import router from "../../../routing/Router";
import {Error as AuthErrorPage} from "serpotrack-frontend-authentication";

const Error = () => {
    return (
        <AuthErrorPage
            links={[
                {
                    text: "Dashboard",
                    href: router.dashboard.absolutePath()
                }
            ]}
        />
    );
};

export default Error;