import React, {useMemo} from "react";
import {capitalizeFirstLetter, DataTable, DataTableColumn, TableButton, TableColumn} from "peggirkit";
import {serpotrackAxios} from "serpotrack-connector";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/solid";
import router from "../../../routing/Router";

const UsersDataTable = () => {
    const columns: (TableColumn & DataTableColumn)[] = useMemo(() => {
        return [
            {
                dataName: "id",
                name: "ID",
                sortable: false,
                secondary: true
            },
            {
                dataName: "username",
                name: "E-mail address",
                sortable: true,
                secondary: false,
                formatter: (email: string) => capitalizeFirstLetter(email)
            },
            {
                dataName: "verified",
                name: "Verified",
                sortable: false,
                secondary: true,
                formatter: (verified: boolean) => verified
                    ? <CheckCircleIcon className={"w-5 text-green-600"}/>
                    : <XCircleIcon className={"w-5 text-red-600"}/>
            },
            {
                dataName: "mollieCustomerId",
                name: "Mollie ID",
                sortable: false,
                secondary: true,
            },
            {
                dataName: "creationDate",
                name: "Registered on",
                sortable: true,
                sorted: "desc",
                secondary: true,
                formatter: (date: string): string => (new Date(date).toLocaleDateString())
            },
            {
                hideLabel: true,
                name: "Options",
                sortable: false,
                alignRight: true,
                secondary: true,
                formatter: (_, user): JSX.Element => (
                    <TableButton
                        text={"View"}
                        href={router.user.absolutePath(user.id)}
                    />
                )
            }
        ]
    }, []);

    return (
        <DataTable
            withUrlPageParameter={true}
            scrollToTopOnPageChange={true}
            initialPageRequest={{
                axios: serpotrackAxios,
                baseUrl: `${process.env.SERPOTRACK_API_BASE_URL}/admin/users`,
                pageSize: 20,
                pageNumber: 0
            }}
            columns={columns}
            summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                `Showing ${from} to ${to} of ${total}`
            )}
            messages={{
                next: "Next",
                previous: "Previous"
            }}
            className={"mt-8"}
        />
    );
};

export default UsersDataTable;