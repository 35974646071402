import React, {useEffect, useState} from "react";
import {
    ArrowLeftOnRectangleIcon,
    FolderIcon,
    PencilSquareIcon,
    QuestionMarkCircleIcon,
    UsersIcon
} from "@heroicons/react/24/outline";
import {
    breadcrumbItemBlog,
    breadcrumbItemDashboard,
    breadcrumbItemHelpCenter,
    breadcrumbItemUsers
} from "../../../../routing/Router";
// @ts-ignore
import logo from "../../../../assets/img/icon.svg";
import {DashboardFrame, PrimaryNavigationItemProps, SecondaryNavigationItemProps, useAuth} from "peggirkit";

type Props = {
    title: string,
    children: JSX.Element | JSX.Element[]
};

const SerpotrackFrame = ({title, children}: Props) => {
    const [isSigningOut, setIsSigningOut] = useState(false);
    const authContext = useAuth();

    useEffect(() => {
        if (!isSigningOut) {
            return;
        }

        authContext?.onSignOut().catch(() => {
            setIsSigningOut(false);
        });
    }, [isSigningOut]);

    const navigation: PrimaryNavigationItemProps[] = [
        {
            name: breadcrumbItemDashboard.name,
            href: breadcrumbItemDashboard.href,
            icon: FolderIcon,
            current: false
        },
        {
            name: breadcrumbItemBlog.name,
            href: breadcrumbItemBlog.href,
            icon: PencilSquareIcon,
            current: false
        },
        {
            name: breadcrumbItemHelpCenter.name,
            href: breadcrumbItemHelpCenter.href,
            icon: QuestionMarkCircleIcon,
            current: false
        },
        {
            name: breadcrumbItemUsers.name,
            href: breadcrumbItemUsers.href,
            icon: UsersIcon,
            current: false
        },
        {
            name: "Sign out",
            onClick: () => setIsSigningOut(true),
            icon: ArrowLeftOnRectangleIcon,
            current: false
        },
    ];

    const secondaryNavigation: SecondaryNavigationItemProps[] = [
        {
            name: "App",
            href: "https://app.serpotrack.com/"
        },
        {
            name: "Landing",
            href: "https://serpotrack.com/"
        },
    ];

    return (
        <DashboardFrame
            appName={"Serpotrack Admin"}
            title={title}
            logo={logo}
            primaryNavigation={navigation}
            secondaryNavigation={secondaryNavigation}
            secondaryNavigationTitle={"External"}
        >
            {children}
        </DashboardFrame>
    );
};

export default SerpotrackFrame;