import React from "react";
import {SubscriptionDetails, UserDetails} from "../../../../../data/Entities";
import {SectionHeading, Table} from "peggirkit";
import PlanDetailsSection from "./PlanDetailsSection";
import InvoicesSection from "./InvoicesSection";

type Props = {
    user: UserDetails
};

const FinancialsTab = ({user}: Props) => {
    return (
        <>
            <SectionHeading
                title={"Subscriptions"}
                description={"Overview of all subscriptions of this user."}
            />
            <Table
                columns={[
                    {name: "ID", sortable: false, secondary: false},
                    {name: "Plan", sortable: false, secondary: true},
                    {name: "Invoices", sortable: false, secondary: true},
                    {name: "Active", sortable: false, secondary: true},
                    {name: "Started on", sortable: false, secondary: true},
                    {name: "Ends on", sortable: false, secondary: true},
                    {name: "Mollie sub. ID", sortable: false, secondary: true},
                    {name: "Monthly", sortable: false, secondary: true},
                ]}
                data={user.subscriptions.map((s: SubscriptionDetails) => ({
                    ["ID"]: s.id,
                    ["Plan"]: <PlanDetailsSection plan={s.plan}/>,
                    ["Invoices"]: <InvoicesSection invoices={s.invoices} plan={s.plan}/>,
                    ["Active"]: !s.cancelled ? "Yes" : "No",
                    ["Started on"]: new Date(s.startDate).toLocaleDateString(),
                    ["Ends on"]: new Date(s.endDate).toLocaleDateString(),
                    ["Mollie sub. ID"]: s.mollieSubscriptionId,
                    ["Monthly"]: s.monthly ? "Yes" : "No",
                }))}
            />
        </>
    );
};

export default FinancialsTab;