import React, {useState} from "react";
import {SlideOver, Table, TableButton} from "peggirkit";
import {InvoiceDetails, PlanDetails} from "../../../../../data/Entities";

type Props = {
    invoices: InvoiceDetails[],
    plan: PlanDetails
};

const InvoicesSection = ({invoices, plan}: Props) => {
    const [showInvoices, setShowInvoices] = useState(false);
    return (
        <>
            <TableButton
                text={`Invoices (${invoices.length})`}
                href={"#"}
                onClick={e => {
                    e.preventDefault();
                    setShowInvoices(true);
                }}
            />
            <SlideOver
                open={showInvoices}
                setOpen={setShowInvoices}
                title={`Invoices for: ${plan.name}`}
            >
                <Table
                    columns={[
                        {name: "ID", sortable: false, secondary: false},
                        {name: "Paid on", sortable: false, secondary: true},
                        {name: "Refunded on", sortable: false, secondary: true},
                        {name: "Method", sortable: false, secondary: true},
                        {name: "Price ex. tax", sortable: false, secondary: true},
                        {name: "Price in. tax", sortable: false, secondary: true},
                        {name: "Tax", sortable: false, secondary: true},
                    ]}
                    data={invoices.map(i => ({
                        ["ID"]: i.id,
                        ["Paid on"]: new Date(i.paidOn).toLocaleDateString(),
                        ["Refunded on"]: i.refundedOn === null
                            ? "-"
                            : new Date(i.refundedOn).toLocaleDateString(),
                        ["Method"]: i.paymentMethod,
                        ["Price ex. tax"]: `${i.itemPriceExTax} (€ct.)`,
                        ["Price in. tax"]: `${i.itemPriceInTax} (€ct.)`,
                        ["Tax"]: `${i.taxPercentage}%`,
                    }))}
                />
            </SlideOver>
        </>
    );
};

export default InvoicesSection;