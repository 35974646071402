import React, {useEffect, useState} from "react";
import {TabControl, TextSkeleton} from "peggirkit";
import {CreditCardIcon, FolderIcon, UserIcon} from "@heroicons/react/24/outline";
import GeneralTab from "./general/GeneralTab";
import FinancialsTab from "./financials/FinancialsTab";
import ProjectsTab from "./projects/ProjectsTab";
import {getUserDetails} from "../../../../data/SerpotrackAdmin";
import {UserDetails} from "../../../../data/Entities";

type Props = {
    userId?: number
};

const DetailsTab = ({userId}: Props) => {
    const [user, setUser] = useState<UserDetails | null>(null);

    useEffect(() => {
        if (userId === undefined) {
            return;
        }

        getUserDetails(userId).then(u => setUser(u));
    }, [userId]);

    if (userId === undefined || user === null) {
        return (
            <div className={"mt-10"}>
                <TextSkeleton width={"max-w-[200px]"}/>
            </div>
        );
    }

    return (
        <TabControl
            selectTabText={"Select tab"}
            tabs={[
                {
                    current: true,
                    defaultTab: true,
                    icon: UserIcon,
                    name: "General",
                    hash: "general",
                    content: <GeneralTab user={user}/>
                },
                {
                    current: false,
                    defaultTab: false,
                    icon: CreditCardIcon,
                    name: "Financials",
                    hash: "financials",
                    content: <FinancialsTab user={user}/>
                },
                {
                    current: false,
                    defaultTab: false,
                    icon: FolderIcon,
                    name: "Projects",
                    hash: "projects",
                    content: <ProjectsTab user={user}/>
                }
            ]}
        />
    );
};

export default DetailsTab;