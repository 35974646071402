import {Invoice, Plan, Project, Subscription, User} from "serpotrack-connector";

export enum ImageType {
    banner = 0,
    content = 1,
}

export type BlogPost = {
    id: number,
    lastEditedDate?: string,
    publishDate: string,
    title: string,
    slug: string,
    description: string,
    banner: string,
    content: string
};

export type Role = {
    id: number,
    name: string
};

export type InvoiceDetails = Invoice & {
    itemPriceExTax: number,
    taxPercentage: number,
    itemDescription: string,
    paymentMethod: string,
};

export type PlanDetails = Plan & {
    id: number,
    price_ex_tax_monthly: number,
    price_ex_tax_yearly: number,
    price_in_tax_monthly: number,
    price_in_tax_yearly: number,
    tax_percentage: number,
    invoice_description_monthly: string,
    invoice_description_yearly: string
};

export type SubscriptionDetails = Subscription & {
    id: number,
    startDate: string,
    mollieSubscriptionId: string,
    invoices: InvoiceDetails[],
    monthly: boolean,
    plan: PlanDetails
};

export type UserDetails = User & {
    id: number,
    creationDate: string,
    mollieCustomerId: string,
    roles: Role[],
    projects: Project[],
    subscriptions: SubscriptionDetails[]
};

export type HelpCenterPostOverviewItem = {
    id: number,
    lastEditedDate?: string,
    title: string,
    orderNumber?: number,
};

export type HelpCenterPost = HelpCenterPostOverviewItem & {
    helpCenterCategory: HelpCenterCategory,
    publishDate: string,
    slug: string,
    description: string,
    content: string,
};

export type HelpCenterCategory = {
    id: number | null,
};

export type HelpCenterCategoryDetails = HelpCenterCategory & {
    name: string,
    slug: string,
    orderNumber: number,
    posts: HelpCenterPostOverviewItem[]
};

