import React, {useMemo} from "react";
import {DataTable, DataTableColumn, TableButton, TableColumn} from "peggirkit";
import {serpotrackAxios} from "serpotrack-connector";
import router from "../../../routing/Router";

const BlogDataTable = () => {
    const columns: (TableColumn & DataTableColumn)[] = useMemo(() => {
        return [
            {
                dataName: "id",
                name: "ID",
                sortable: false,
                secondary: true
            },
            {
                dataName: "title",
                name: "Title",
                sortable: true,
                secondary: false,
            },
            {
                dataName: "creationDate",
                name: "Created on",
                sortable: true,
                sorted: "desc",
                secondary: true,
                formatter: (date: string): string => (new Date(date).toLocaleDateString())
            },
            {
                hideLabel: true,
                name: "Options",
                sortable: false,
                alignRight: true,
                secondary: true,
                formatter: (_, data): JSX.Element => (
                    <TableButton
                        text={"Edit"}
                        href={router.editBlogPost.absolutePath(data.id)}
                    />
                )
            }
        ]
    }, []);

    return (
        <DataTable
            withUrlPageParameter={true}
            scrollToTopOnPageChange={true}
            initialPageRequest={{
                axios: serpotrackAxios,
                baseUrl: `${process.env.SERPOTRACK_API_BASE_URL}/admin/blog`,
                pageSize: 20,
                pageNumber: 0
            }}
            columns={columns}
            summaryFormatter={(from: number, to: number, total: number): JSX.Element | string => (
                `Showing ${from} to ${to} of ${total}`
            )}
            messages={{
                next: "Next",
                previous: "Previous"
            }}
            className={"mt-8"}
        />
    );
};

export default BlogDataTable;