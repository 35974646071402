import React, {useEffect, useState} from "react";
import {AlertModal, AlertText, NotificationService} from "peggirkit";
import {deleteHelpCenterPost} from "../../../../data/SerpotrackAdmin";
import router from "../../../../routing/Router";
import {useNavigate} from "react-router-dom";

type Props = {
    helpCenterPostId: number,
    show: boolean,
    setShow: (show: boolean) => void,
};

const DeleteHelpCenterPostModal = ({helpCenterPostId, show, setShow}: Props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!loading) {
            return;
        }

        deleteHelpCenterPost(helpCenterPostId)
            .then(() => navigate(router.helpCenter.absolutePath()))
            .catch(() => {
                NotificationService.notify({type: "danger", text: "Error deleting!"});
                setLoading(false);
            });
    }, [loading]);

    return (
        <AlertModal
            type={"danger"}
            title={"Confirm delete post"}
            loading={loading}
            show={show}
            onClose={() => setShow(false)}
            cancelText={"Cancel"}
            continueText={"Delete"}
            onContinue={() => setLoading(true)}
        >
            <AlertText>
                Are you sure you want to delete this help center post?
            </AlertText>
        </AlertModal>
    );
};

export default DeleteHelpCenterPostModal;