import {BreadcrumbItem} from "peggirkit";

type Route = {
    relativePath: string,
    absolutePath: (...param: any) => string
}

type Router = {
    [routes: string]: Route
}

// Base:
const index: Route = {
    relativePath: "/",
    absolutePath: () => "/"
};

// Unauthenticated:
const authPart = "auth";
const auth: Route = {
    relativePath: authPart,
    absolutePath: () => `${index.absolutePath()}${authPart}`
};

const signInPart = "sign-in";
const signIn: Route = {
    relativePath: signInPart,
    absolutePath: () => `${auth.absolutePath()}/${signInPart}`
};

// // Authenticated:
const dashboardPart = "dashboard";
const dashboard: Route = {
    relativePath: dashboardPart,
    absolutePath: () => `${index.absolutePath()}${dashboardPart}`
};

const usersPart = "users";
const users: Route = {
    relativePath: usersPart,
    absolutePath: () => `${index.absolutePath()}${usersPart}`
};

const userPart = ":userId";
const user: Route = {
    relativePath: userPart,
    absolutePath: (userId: number) => `${users.absolutePath()}/${userId}`
};

const blogPart = "blog";
const blog: Route = {
    relativePath: blogPart,
    absolutePath: () => `${index.absolutePath()}${blogPart}`
};

const newBlogPostPart = "new";
const newBlogPost: Route = {
    relativePath: newBlogPostPart,
    absolutePath: () => `${blog.absolutePath()}/${newBlogPostPart}`
};

const editBlogPostPart = ":blogPostId";
const editBlogPost: Route = {
    relativePath: editBlogPostPart,
    absolutePath: (blogPostId: number) => `${blog.absolutePath()}/${blogPostId}`
};

const helpCenterPart = "help";
const helpCenter: Route = {
    relativePath: helpCenterPart,
    absolutePath: () => `${index.absolutePath()}${helpCenterPart}`
};

const newHelpCenterPostPart = "new";
const newHelpCenterPost: Route = {
    relativePath: newHelpCenterPostPart,
    absolutePath: (helpCenterCategoryId?: number) => {
        const url = `${helpCenter.absolutePath()}/${newHelpCenterPostPart}`;
        return helpCenterCategoryId
            ? `${url}?category=${helpCenterCategoryId}`
            : url
    }
};

const editHelpCenterPostPart = ":helpCenterPostId";
const editHelpCenterPost: Route = {
    relativePath: editHelpCenterPostPart,
    absolutePath: (helpCenterPostId: number) => `${helpCenter.absolutePath()}/${helpCenterPostId}`
};

const router: Router = {};
router.index = index;
router.auth = auth;
router.signIn = signIn;
router.dashboard = dashboard;
router.users = users;
router.user = user;
router.blog = blog;
router.editBlogPost = editBlogPost;
router.newBlogPost = newBlogPost;
router.helpCenter = helpCenter;
router.editHelpCenterPost = editHelpCenterPost;
router.newHelpCenterPost = newHelpCenterPost;

// Breadcrumbs:
export const breadcrumbItemDashboard: BreadcrumbItem = {
    name: "Dashboard",
    href: router.dashboard.absolutePath()
};

export const breadcrumbItemUsers: BreadcrumbItem = {
    name: "Users",
    href: router.users.absolutePath()
};

export const breadcrumbItemUser = (userId: number): BreadcrumbItem => ({
    name: `User ${userId}`,
    href: router.user.absolutePath(userId)
});

export const breadcrumbItemBlog: BreadcrumbItem = {
    name: "Blog",
    href: router.blog.absolutePath()
};

export const breadcrumbItemEditBlogPost = (blogPostId: number): BreadcrumbItem => ({
    name: `Edit post ${blogPostId}`,
    href: router.editBlogPost.absolutePath(blogPostId)
});

export const breadcrumbItemHelpCenter: BreadcrumbItem = {
    name: "Help center",
    href: router.helpCenter.absolutePath()
};

export const breadcrumbItemEditHelpCenterPost = (helpCenterPostId: number): BreadcrumbItem => ({
    name: `Edit post ${helpCenterPostId}`,
    href: router.editHelpCenterPost.absolutePath(helpCenterPostId)
});

export default router;