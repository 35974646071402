import React from "react";
import RolesSection from "./RolesSection";
import GeneralSection from "./GeneralSection";
import {UserDetails} from "../../../../../data/Entities";

type Props = {
    user: UserDetails
};

const GeneralTab = ({user}: Props) => {
    return (
        <div>
            <GeneralSection user={user}/>
            <RolesSection user={user}/>
        </div>
    );
};

export default GeneralTab;