import {Message, serpotrackAxios} from "serpotrack-connector";
import {BlogPost, HelpCenterCategoryDetails, HelpCenterPost, ImageType, UserDetails} from "./Entities";

export const getBlogPost = (id: number): Promise<BlogPost> => (
    serpotrackAxios
        .get(`/admin/blog/${id}`)
        .then((response) => response.data)
);

export const saveBlogPost = (post: BlogPost): Promise<BlogPost> => (
    serpotrackAxios
        .put("/admin/blog", post)
        .then((response) => response.data)
);

export const deleteBlogPost = (id: number): Promise<Message> => (
    serpotrackAxios
        .delete(`/admin/blog/${id}`)
        .then((response) => response.data)
);

export const uploadImage = (data: FormData, conversionType: ImageType): Promise<Message> => (
    serpotrackAxios
        .post(`/admin/asset/image?conversionType=${conversionType}`, data)
        .then((response) => response.data)
);

export const getUserDetails = (userId: number): Promise<UserDetails> => (
    serpotrackAxios
        .get(`/admin/users/${userId}`)
        .then((response) => response.data)
);

export const listHelpCenter = (): Promise<HelpCenterCategoryDetails[]> => (
    serpotrackAxios
        .get("/admin/help?page=0&size=200&sort=orderNumber,asc")
        .then((response) => response.data.content)
);

export const getHelpCenterPost = (id: number): Promise<HelpCenterPost> => (
    serpotrackAxios
        .get(`/admin/help/${id}`)
        .then((response) => response.data)
);

export const saveHelpCenterPost = (post: HelpCenterPost): Promise<HelpCenterPost> => (
    serpotrackAxios
        .put("/admin/help", post)
        .then((response) => response.data)
);

export const deleteHelpCenterPost = (id: number): Promise<Message> => (
    serpotrackAxios
        .delete(`/admin/help/${id}`)
        .then((response) => response.data)
);
