import React from "react";
import {AuthProvider, ProtectedRoute} from "peggirkit";
import {Navigate, Route, Routes} from "react-router-dom";
import router from "./routing/Router";
import SignIn from "./component/page/unauthenticated/SignIn";
import Dashboard from "./component/page/authenticated/Dashboard";
import {finishSignInWithTotpMfa, getCurrentUser, signIn, signOut} from "serpotrack-connector";
import Users from "./component/page/authenticated/Users";
import User from "./component/page/authenticated/User";
import Error from "./component/page/unauthenticated/Error";
import Blog from "./component/page/authenticated/blog/Blog";
import EditBlogPost from "./component/page/authenticated/blog/EditBlogPost";
import HelpCenter from "./component/page/authenticated/helpCenter/HelpCenter";
import EditHelpCenterPost from "./component/page/authenticated/helpCenter/EditHelpCenterPost";

const App = () => {
    return (
        <AuthProvider
            signIn={signIn}
            signOut={signOut}
            getCurrentUser={getCurrentUser}
            finishSignInWithTotp={finishSignInWithTotpMfa}
            onSignInRedirectTo={router.index.absolutePath()}
            onSignOutRedirectTo={router.signIn.absolutePath()}
        >
            <Routes>
                <Route path={router.auth.relativePath}>
                    <Route path={router.signIn.relativePath} element={<SignIn/>}/>
                </Route>
                <Route path={router.index.relativePath} element={<Navigate to={router.dashboard.relativePath}/>}/>
                <Route path={router.dashboard.relativePath}>
                    <Route
                        index
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Dashboard/>
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path={router.users.relativePath}>
                    <Route
                        index
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Users/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={router.user.relativePath}
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <User/>
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path={router.blog.relativePath}>
                    <Route
                        index
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <Blog/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={router.editBlogPost.relativePath}
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <EditBlogPost/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={router.newBlogPost.relativePath}
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <EditBlogPost/>
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path={router.helpCenter.relativePath}>
                    <Route
                        index
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <HelpCenter/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={router.editHelpCenterPost.relativePath}
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <EditHelpCenterPost/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={router.newHelpCenterPost.relativePath}
                        element={
                            <ProtectedRoute unauthenticatedRedirect={router.signIn.absolutePath()}>
                                <EditHelpCenterPost/>
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path="*" element={<Error/>}/>
            </Routes>
        </AuthProvider>
    );
}

export default App;