import React, {useEffect, useState} from "react";
import {BreadcrumbItem, BreadcrumbsContent, NotificationService, PageHeading} from "peggirkit";
import EditHelpCenterPostForm from "./EditHelpCenterPostForm";
import {useForm} from "react-hook-form";
import {HelpCenterPost} from "../../../../data/Entities";
import {UseFormSetValue} from "react-hook-form/dist/types/form";
import {CheckIcon, Cog6ToothIcon, TrashIcon} from "@heroicons/react/24/outline";
import {getHelpCenterPost, saveHelpCenterPost} from "../../../../data/SerpotrackAdmin";
import {useNavigate} from "react-router-dom";
import router from "../../../../routing/Router";
import DeleteHelpCenterPostModal from "./DeleteHelpCenterPostModal";

type Props = {
    breadcrumbs: BreadcrumbsContent,
    currentBreadcrumb: BreadcrumbItem,
    helpCenterPostId?: string,
    helpCenterCategoryId: number | null
};

const setHelpCenterPostValues = (setValue: UseFormSetValue<HelpCenterPost>, helpCenterPost: HelpCenterPost) => {
    setValue("id", helpCenterPost.id);
    setValue("title", helpCenterPost.title);
    setValue("orderNumber", helpCenterPost.orderNumber);
    setValue("slug", helpCenterPost.slug);
    setValue("description", helpCenterPost.description);
    setValue("content", helpCenterPost.content);
    setValue("publishDate", helpCenterPost.publishDate);
    setValue("lastEditedDate", helpCenterPost.lastEditedDate);
    setValue("helpCenterCategory.id", helpCenterPost.helpCenterCategory.id);
}

const HelpCenterPostEditContainer = ({
                                         breadcrumbs,
                                         currentBreadcrumb,
                                         helpCenterPostId,
                                         helpCenterCategoryId
                                     }: Props) => {
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const [formDisabled, setFormDisabled] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const {register, setValue, getValues, watch, handleSubmit, formState: {errors}} = useForm<HelpCenterPost>({
        defaultValues: {
            id: undefined,
            title: "",
            orderNumber: undefined,
            slug: "",
            description: "",
            content: "",
            publishDate: "",
            lastEditedDate: undefined,
            helpCenterCategory: {
                id: helpCenterCategoryId
            }
        }
    });
    const onSubmit = handleSubmit(() => setSaving(true));

    // Load existing data
    useEffect(() => {
        if (!helpCenterPostId) {
            // New help center post
            setFormDisabled(false);
            return;
        }

        getHelpCenterPost(parseInt(helpCenterPostId)).then((existingPost: HelpCenterPost) => {
            setHelpCenterPostValues(setValue, existingPost);
            setFormDisabled(false);
        });
    }, []);

    // Save post
    useEffect(() => {
        if (!saving) {
            return;
        }

        const values = getValues();
        saveHelpCenterPost({
            id: values.id,
            title: values.title,
            orderNumber: values.orderNumber,
            slug: values.slug,
            description: values.description,
            content: values.content,
            publishDate: values.publishDate,
            helpCenterCategory: {
                id: values.helpCenterCategory.id
            }
        }).then((saved: HelpCenterPost) => {
            setSaving(false);
            setHelpCenterPostValues(setValue, saved);
            NotificationService.notify({type: "success", text: "Saved!"});
            if (!helpCenterPostId) {
                navigate(router.editHelpCenterPost.absolutePath(saved.id));
            }
        }).catch(() => {
            setSaving(false);
            NotificationService.notify({type: "danger", text: "Error saving post!"});
        });
    }, [saving]);

    return (
        <>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={currentBreadcrumb.name}
                actions={{
                    disabled: saving,
                    moreButtonText: "More",
                    actions: [
                        {
                            actionType: "dropdown",
                            text: "Options",
                            primary: false,
                            iconOnly: false,
                            icon: Cog6ToothIcon,
                            options: [
                                [
                                    {
                                        text: "Delete",
                                        type: "danger",
                                        icon: TrashIcon,
                                        onClick: () => setShowDeleteModal(true),
                                        disabled: saving || !watch("id"),
                                    }
                                ]
                            ]
                        },
                        {
                            actionType: "button",
                            text: "Save",
                            primary: true,
                            icon: CheckIcon,
                            onClick: onSubmit
                        },
                    ]
                }}
            />


            <div className={"w-full md:mt-16 pb-48"}>
                <EditHelpCenterPostForm
                    onSubmit={onSubmit}
                    disabled={formDisabled || saving}
                    register={register}
                    watch={watch}
                    errors={errors}
                    setValue={setValue}
                />
            </div>

            <DeleteHelpCenterPostModal
                helpCenterPostId={watch("id")}
                show={showDeleteModal}
                setShow={setShowDeleteModal}
            />
        </>
    );
};

export default HelpCenterPostEditContainer;