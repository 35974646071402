import React from "react";
import {breadcrumbItemDashboard, breadcrumbItemUser, breadcrumbItemUsers} from "../../../routing/Router";
import {BreadcrumbsContent, PageHeading} from "peggirkit";
import SerpotrackFrame from "./layout/SerpotrackFrame";
import {useParams} from "react-router-dom";
import DetailsTab from "../../widgets/users/user/DetailsTab";

const Users = () => {
    const {userId} = useParams();
    const breadcrumbItem = breadcrumbItemUser(userId !== undefined ? parseInt(userId) : -1);
    const breadcrumbs: BreadcrumbsContent = {
        backText: "Back",
        items: [breadcrumbItemDashboard, breadcrumbItemUsers, breadcrumbItem]
    };

    return (
        <SerpotrackFrame title={breadcrumbItem.name}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={breadcrumbItem.name}
            />
            <DetailsTab userId={userId !== undefined ? parseInt(userId) : undefined}/>
        </SerpotrackFrame>
    );
};

export default Users;