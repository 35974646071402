import React, {useState} from "react";
import {FieldErrorsImpl, UseFormRegister, UseFormWatch} from "react-hook-form";
import {HelpCenterPost} from "../../../../data/Entities";
import {Card, DayPicker, Form, InputField, InputSelect} from "peggirkit";
import dayjs from "dayjs";
import {CalendarIcon} from "@heroicons/react/24/outline";
import {UseFormSetValue} from "react-hook-form/dist/types/form";
import ContentImageUploader from "../../ContentImageUploader";

type Props = {
    onSubmit: () => void,
    disabled: boolean,
    register: UseFormRegister<HelpCenterPost>,
    watch: UseFormWatch<HelpCenterPost>,
    errors: FieldErrorsImpl<HelpCenterPost>,
    setValue: UseFormSetValue<HelpCenterPost>,
};

const EditHelpCenterPostForm = ({
                                    onSubmit,
                                    disabled,
                                    register,
                                    watch,
                                    errors,
                                    setValue,
                                }: Props) => {
    const [showSelectPublishDay, setShowSelectPublishDay] = useState(false);

    return (
        <Card
            header={{title: "Editor"}}
        >
            <Form
                onSubmit={onSubmit}
                error={null}
                success={null}
            >
                <InputField
                    type={"number"}
                    id={"id"}
                    displayName={"ID"}
                    reactHookForm={{...register("id")}}
                    disabled={true}
                    error={errors.id && errors.id.message}
                />

                {/* Category */}
                <InputSelect
                    id={"category"}
                    displayName={"Category"}
                    options={[
                        {value: 1, label: "Getting started"},
                        {value: 2, label: "Account"},
                        {value: 3, label: "FAQ"},
                    ]}
                    reactHookForm={{
                        ...register("helpCenterCategory.id", {
                            required: "Select a parent category."
                        })
                    }}
                    disabled={disabled}
                    error={errors.helpCenterCategory?.id && errors.helpCenterCategory?.id.message}
                />

                {/* Order number */}
                <InputField
                    type={"number"}
                    id={"orderNumber"}
                    displayName={"Order number"}
                    reactHookForm={{
                        ...register("orderNumber", {
                            valueAsNumber: true,
                            required: "Enter order number.",
                        })
                    }}
                    disabled={disabled}
                    error={errors.orderNumber && errors.orderNumber.message}
                />

                {/* Publish date */}
                <InputField
                    type={"text"}
                    id={"publishDate"}
                    displayName={"Publish on"}
                    tip={"Select a future day to schedule or keep a post private."}
                    reactHookForm={{
                        ...register("publishDate", {
                            required: true,
                            maxLength: {
                                value: 256,
                                message: "Too long"
                            }
                        })
                    }}
                    error={errors.publishDate && errors.publishDate.message}
                    disabled={disabled}
                    trailingButton={{
                        type: "gray",
                        text: "Pick a day",
                        icon: CalendarIcon,
                        disabled: disabled,
                        onClick: (e) => {
                            e.preventDefault();
                            setShowSelectPublishDay(true);
                        }
                    }}
                />
                <DayPicker
                    key={watch("publishDate")}
                    defaultSelected={watch("publishDate") ? new Date(watch("publishDate")) : new Date()}
                    title={"Publish day"}
                    cancelText={"Cancel"}
                    continueText={"Ok"}
                    show={showSelectPublishDay}
                    setShow={setShowSelectPublishDay}
                    onSelect={(selected) => {
                        if (selected) {
                            setValue("publishDate", dayjs(selected).format("YYYY-MM-DD[T]HH:mm:ss[+0000]"));
                        }
                    }}
                />

                {/* Last edited date */}
                <InputField
                    type={"text"}
                    id={"lastEditedDate"}
                    displayName={"Last edited"}
                    reactHookForm={{...register("lastEditedDate")}}
                    tip={"Automatically set"}
                    disabled={true}
                    error={errors.lastEditedDate && errors.lastEditedDate.message}
                />

                {/* Title */}
                <InputField
                    type={"text"}
                    id={"title"}
                    displayName={"Title"}
                    reactHookForm={{
                        ...register("title", {
                            required: "Enter title.",
                            minLength: {
                                value: 1,
                                message: "Too short.",
                            },
                            maxLength: {
                                value: 512,
                                message: "Too long.",
                            },
                        })
                    }}
                    tip={`${watch("title").length}/512`}
                    disabled={disabled}
                    error={errors.title && errors.title.message}
                />

                {/* Slug */}
                <InputField
                    type={"text"}
                    id={"slug"}
                    displayName={"Slug"}
                    addOn={"/help/<category>/"}
                    reactHookForm={{
                        ...register("slug", {
                            required: "Enter slug.",
                            minLength: {
                                value: 1,
                                message: "Too short.",
                            },
                            maxLength: {
                                value: 512,
                                message: "Too long.",
                            },
                        })
                    }}
                    tip={`${watch("slug").length}/512`}
                    disabled={disabled}
                    error={errors.slug && errors.slug.message}
                />

                {/* Description */}
                <InputField
                    type={"textarea"}
                    textareaHeight={"h-16"}
                    id={"description"}
                    displayName={"Description"}
                    reactHookForm={{
                        ...register("description", {
                            required: "Enter description.",
                            minLength: {
                                value: 1,
                                message: "Too short.",
                            },
                            maxLength: {
                                value: 156,
                                message: "Too long.",
                            },
                        })
                    }}
                    tip={`${watch("description").length}/156`}
                    disabled={disabled}
                    error={errors.description && errors.description.message}
                />

                {/* Content */}
                <InputField
                    type={"textarea"}
                    textareaHeight={"h-[600px]"}
                    id={"content"}
                    displayName={"Content"}
                    reactHookForm={{
                        ...register("content", {
                            required: "Enter content.",
                            minLength: {
                                value: 1,
                                message: "Too short.",
                            },
                            maxLength: {
                                value: 20000,
                                message: "Too long.",
                            },
                        })
                    }}
                    tip={`${watch("content").length} character(s)`}
                    disabled={disabled}
                    error={errors.content && errors.content.message}
                    after={
                        <div className={"mt-2"}>
                            <ContentImageUploader
                                disabled={disabled}
                                afterUpload={(htmlCode: string) => {
                                    setValue("content", watch("content") + "\n" + htmlCode);
                                }}
                            />
                        </div>
                    }
                />
            </Form>
        </Card>
    );
};

export default EditHelpCenterPostForm;