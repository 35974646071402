import React from "react";
import {breadcrumbItemDashboard, breadcrumbItemHelpCenter} from "../../../../routing/Router";
import {BreadcrumbsContent, PageHeading} from "peggirkit";
import SerpotrackFrame from "../layout/SerpotrackFrame";
import CategoriesOverview from "../../../widgets/helpCenter/CategoriesOverview";

const HelpCenter = () => {
    const breadcrumbs: BreadcrumbsContent = {
        backText: "Back",
        items: [breadcrumbItemDashboard, breadcrumbItemHelpCenter]
    };

    return (
        <SerpotrackFrame title={breadcrumbItemHelpCenter.name}>
            <PageHeading
                breadcrumbsContent={breadcrumbs}
                title={breadcrumbItemHelpCenter.name}
            />

            <CategoriesOverview/>
        </SerpotrackFrame>
    );
};

export default HelpCenter;