import React, {useEffect, useState} from "react";
import {HelpCenterCategoryDetails} from "../../../data/Entities";
import {listHelpCenter} from "../../../data/SerpotrackAdmin";
import Category from "./Category";

const CategoriesOverview = () => {
    const [categories, setCategories] = useState<HelpCenterCategoryDetails[]>([]);

    useEffect(() => {
        listHelpCenter().then(hc => setCategories(hc));
    }, []);

    return (
        <div className={"flex flex-col gap-24 mt-8"}>
            {categories.map((c, i) => <Category key={i} {...c}/>)}
        </div>
    );
};

export default CategoriesOverview;